import * as React from "react";
import Layout from "../../../layouts/en";
import PageHead from "../../../components/shared/PageHead";
import Intro from "../../../components/shared/Intro";
import * as styles from "../../../styles/pages/articles/index.module.css";
import content from "../../../data/content/articles/patent-reg/article-en";
import money from "../../../assets/images/articles/money.png";
import ccc from "../../../assets/images/articles/c.png";
import document from "../../../assets/images/articles/document.svg";
import checklist from "../../../assets/images/articles/checklist.svg";

const PatentReg = (props) => {
  const {
    sIntroSection,
    sIntro,
    sArticleContainer,
    sPR1Sec,
    sPR2Sec,
    sPR3Sec,
    sPR4Sec,
    sPR5Sec,
    sPR6Sec,
  } = styles;
  const { seo, intro, articleHeader, secP, thrdP, frthP, ffthP, sixP, dir } =
    content;

  return (
    <Layout location={props.location}>
      <PageHead pageHeadData={seo} />
      <article lang="en" className="article" dir={dir}>
        <Intro content={intro} styles={{ sIntroSection, sIntro }} />
        <div className={sArticleContainer}>
          <section className={sPR1Sec}>
            <p>{articleHeader.desc}</p>
          </section>
          <section className={sPR2Sec}>
            <div>
              <h2>{secP.title}</h2>
              {secP.p.map((p, index) => (
                <p key={index}>{p}</p>
              ))}
            </div>
            <div>
              <img src={checklist} alt="checklist" />
            </div>
          </section>
          <section className={sPR3Sec}>
            <div>
              <h2>{thrdP.title}</h2>
            </div>
            <div>
              {thrdP.p.map((p, index) => (
                <p key={index}>{p}</p>
              ))}
            </div>
          </section>
          <section className={sPR4Sec}>
            <div>
              <h2>{frthP.title}</h2>
              <p>{frthP.p}</p>
            </div>
            <div>
              <img src={money} alt="money" />
            </div>
          </section>
          <section className={sPR5Sec}>
            <div>
              <img src={ccc} alt="copyright" />
            </div>
            <div>
              <h2>{ffthP.title}</h2>
              <p>{ffthP.p}</p>
            </div>
          </section>
          <section className={sPR6Sec}>
            <h2>{sixP.title}</h2>
            <div>
              <p>{sixP.p[0]}</p>
              <img src={document} alt="document" />
            </div>
            <p>{sixP.p[1]}</p>
          </section>
        </div>
      </article>
    </Layout>
  );
};

export default PatentReg;

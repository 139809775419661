module.exports = {
    seo: {
        title : "Jenko - Article - How do I register a Patent?",
        description:  ""
    },
    intro: {
        title: "How do I register\u00A0a\u00A0Patent?"
    },
    articleHeader: {
        desc: "One of the most common topics among entrepreneurs who want to develop an innovative idea for a product is the question of legal protection. Patent registration is the solution that provides the broadest protection but the way to achieve it is long and expensive. In addition, not every invention meets the eligibility requirements for patent registration. Trademark registration is another available option and if your product is a digital one, you should know the copyright laws. We have compiled all these important details for you in the\u00A0following\u00A0article."
    },
    secP: {
        title: "Prerequisites for patent registration\u00A0in\u00A0Israel",
        p: [
            "The authority responsible for issuing patents in the State of Israel is called the Patent Authority and is under the jurisdiction of the Ministry of Justice. A patent application form can be downloaded from the Authority’s website but before completing it, you should check the terms and conditions and understand their\u00A0implications.",
            "The law places three main conditions for patent registration. First, uniqueness. The first thing the authority examines is the existence of other patents and similar products on the market. It is recommended to search the various databases available on the Internet, though this search might not be the most comprehensive. You can use experienced companies in the process if you want to do more of a thorough search. The next condition is the application test. A patent is granted for innovations that have a practical application. Theoretical material cannot be registered as a patent because it already belongs to the academia. The third and final condition for patent registration is the conditions of innovation. Whether it is a product or a process, for it to be considered a patent – it must constitute real progress in its\u00A0field."
        ]
    },
    thrdP: {
        title: "Patent registration process",
        p: [
            "If the patent meets all the eligibility criteria, you can apply for registration. In the application you will be required to write clearly and precisely about the nature of the product and its content, including an explanation of the problem your product solves and a reference to all existing products in the market that are already addressing the problem. In doing so, you will in fact rationally proving the degree of product\u00A0innovation.",
            "After detailing the product and explaining it, you will need to write out your claims. The claims are the specific clauses for which you are requesting exclusive legal rights. During the office’s testing process, they may ask to explain non-ambiguities. Therefore, your full availability for communication with the authority, or availability of someone else with a power of attorney, is required. Power of attorney can be given to a company that will manage the process for\u00A0you.",
            "After approval of the patent and payment of its publication fee, it is launched to the public for a three-month examination period. Any private or commercial entity may object to the patent registration. If resistance comes up, it is considered point by point. If an objection is accepted, the applicant may pay a fine. If the application is accepted, the opponent may pay a fine. If there were no objections at the end of the three-month examination period, the long-awaited patent is now registered. Also expect a registration renewal fee once every few years. The law, of course, may\u00A0change."
        ]
    },
    frthP: {
        title: "Patent registration fee\u00A0-\u00A0costs",
        p: "The patent registration fee will minimally be about 12000 NIS and will be valid for twenty years. In addition, there is a fee for opening the file, which costs 1200 NIS for first-time applicants and 2000 NIS for those who have already applied. How long does it take to register a patent? At any given moment, thousands of patents are under examination and so waiting for an application takes a considerable amount of time, sometimes years. Since this is an expensive and not so simple bureaucratic process, it is advisable to use a company to guide you in the\u00A0process."
    },
    ffthP: {
        title: "Patent registration of\u00A0applications",
        p: "If your app contains a genuine technological innovation that meets the conditions mentioned above, you may want to consider patenting it. If not, since, as mentioned earlier, it is not possible to patent an idea, the prevailing legal recommendation is a thorough record of the application development process. This will help you if you need to provide legal evidence about your intellectual property. In addition, it is worthwhile to accompany the entire work process with confidentiality agreements that will guarantee the loyalty of your partners, or at least significantly increase it. At the end of the development process when a product is already in practice, you are protected by copyright law. This means that the app is your intellectual property. If you want to try your luck in the US market as well, we recommend that you register your work with the US Copyright Office. The process should be accompanied by a company experienced in the process."
    },
    sixP: {
        title: "Trademark registration",
        p: [
            'A trademark is a two-dimensional or three-dimensional visual mark designed to distinguish a company or product graphically. The trademark is another method of building identity and differentiation and its advantage over patent registration is that building this identity is made in the public eye. A good example of the strength of a trademark is, for example, the Coca-Cola symbol known to commonly represent the highest quality of the various Coke drinks. None of the consumers ask themselves “who was the former” or “after whom is the patent registered”. The trademark differentiates the product from the competition and makes it easier for the consumer\u00A0to\u00A0choose.',
            'The Trademark Registrar is also under the jurisdiction of the Patent Authority. The trademark registration examination process takes up to a year. Similar to a patent process, here too, the process will begin by testing for uniqueness in the trademark pool and at the end of the process there will be a three-month examination period during which the public may object to the registration of the trademark. The protection provided by the registration of the trademark is valid for 10 years. You must then apply for a renewal and pay the fee required. The cost of a registration fee for a trademark ranges from NIS 1200 to NIS 1600 and varies according to the field to which the product or company belongs. Additional fees will be required if you wish to submit a trademark that is valid abroad or attempt to expedite\u00A0the\u00A0process.'
        ]
    },
    dir: 'ltr'
};